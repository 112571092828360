<template>
  <div class="bank-list">
    <div class="add-box" @click="$emit('trigger-show-add')">
      <i class="el-icon-plus"> </i>
      <div>添加银行卡</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
.bank-list {
  .add-box {
    cursor: pointer;
    display: inline-block;
    width: 300px;
    height: 190px;

    overflow: hidden;
    padding: 20px 24px;
    border-radius: 3px;
    margin-right: 20px;
    margin-bottom: 20px;

    padding: 0px;
    background: #fff;
    border: 1px solid #dddddd;
    text-align: center;

    &:hover,
    &:active {
      background: #f7f7f7;
      box-shadow: 0 7px 21px rgb(56 56 56 / 15%);
    }

    i {
      color: #dddddd;
      font-size: 49px;
      margin-top: 40px;
      display: block;
    }

    div {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
</style>