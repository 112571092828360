<template>
  <div class="wallet-bank">
    <panel-title icon="el-icon-bank-card" title="银行卡管理" />

    <panel-content>
      <template v-if="!showAdd">
        <span slot="filter" class="filter"> 我的银行卡 </span>
        <wallet-bank-list
          slot="content"
          @trigger-show-add="showAdd = !showAdd"
        />
      </template>
      <template v-else>
        <span slot="filter" class="filter"> 添加银行卡 </span>

        <wallet-bank-add
          slot="content"
          @trigger-show-add="showAdd = !showAdd"
        />
      </template>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
import WalletBankAdd from "./WalletBankAdd.vue";
import WalletBankList from "./WalletBankList.vue";
export default {
  components: { PanelTitle, PanelContent, WalletBankList, WalletBankAdd },

  data() {
    return {
      showAdd: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.wallet-bank {
  .filter {
    font-size: 14px;
    color: #666;
  }
}
</style>