<template>
  <div class="bank-add">
    <el-form label-width="110px" label-position="right" size="medium">
      <el-form-item label="开户名：">
        <el-input style="width: 50%" />
      </el-form-item>

      <el-form-item label="开户行：">
        <el-select
          v-model="value"
          placeholder="请选择开户行"
          style="width: 50%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="开户地区：">
        <el-row style="width: 50%">
          <el-col :span="12">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="银行卡号：">
        <el-input style="width: 50%" />
      </el-form-item>

      <el-form-item label="确认银行卡号：">
        <el-input style="width: 50%" />
      </el-form-item>

      <el-form-item label="开户支行名称：">
        <el-input style="width: 50%" />
      </el-form-item>

      <el-form-item label="交易密码：">
        <el-input style="width: 50%" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary">确认</el-button>
        <el-button @click="$emit('trigger-show-add')">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.bank-add {
}
</style>